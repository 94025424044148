import React, { FC, useState, useEffect, useContext } from 'react';
import CandidateListItem from './CandidateListItem';
import API from '../Client/Client';

import { CandidateGetData } from '../../api';
import SessionContext from '../../contexts/SessionContext';

interface ICandidateListingProps {
    listing: CandidateGetData[];
}

const CandidateListing: FC<ICandidateListingProps> = (props) => {
    const sessionCtx = useContext(SessionContext);
    const [listing, setListing] = useState<CandidateGetData[]>(props.listing);

    useEffect(() => {
        setListing(props.listing);
    }, [props.listing]);

    function deleteCandidateCB(candidateId: string) {
        //sessionCtx.candidate
        const candidateIdx = listing.findIndex(candidate => candidate.id === candidateId);
        const candidate = listing[candidateIdx];
        if (window.confirm(`Are you sure you want to delete candidate '${candidate.name}'?`)) {
            API.deleteCandidate(candidateId)
                .then(resp => {
                    const { error, success } = resp.data;
                    if (success) {
                        // delete SUCCESS
                        // Removing the candidate from the listing
                        const removedCandidate = listing.splice(candidateIdx, 1);
                        // removing candidate from listing
                        setListing([...listing]);
                        // deleting candidate from session data
                        sessionCtx.setCandidate();
                    } else {
                        // delete FAIL
                        alert(`Job Delete Failure: ${error}`);
                    }
                })
                .catch(err => {
                    // delete FAIL
                    alert(`Job Delete Failure: ${err.message}`);
                })
        }
    }

    return (
        <div className="candidates-listing">
        {
            listing.map((candidateInfo) =>
                <CandidateListItem
                    {...candidateInfo}
                    deleteCB={sessionCtx.candidate && sessionCtx.candidate === candidateInfo.id ? deleteCandidateCB : undefined}
                    key={candidateInfo.id}
                />)
        }
        </div>
    )
}
export default CandidateListing;