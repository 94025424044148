import React, { FC } from 'react';
import { RouteProps } from 'react-router';

import CandidateSubmitForm from '../parts/CandidateSubmitForm/CandidateSubmitForm';

interface ISubmitCandidatePage extends RouteProps {}

const SubmitCandidatePage: FC<ISubmitCandidatePage> = (props) => {
    return (
        <CandidateSubmitForm />
    )
}

export default SubmitCandidatePage;