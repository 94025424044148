import React, { FC } from 'react';

import './Table.css';

const TABLE_INLAID_CLASS = 'table-inlaid';
const TR_INLAID_CLASS = 'tr-inlaid';
const TR_INLAID_FIRST_ROW_CLASS = 'tr-inlaid__first-row';
const TR_INLAID_FR_COMBINED_CLASS = TR_INLAID_CLASS + ' ' + TR_INLAID_FIRST_ROW_CLASS;

// For now, we're going to level the cell empty if the value is null
const TABLE_CELL_NULL_PLACEHOLDER = '';

interface ITableProps {
    headers?: any[];
    rows: any[][];
    inlaid?: boolean; // whether the table is inlaid in a cell.  Default: false
}

interface ITableHeaderRowProps {
    headers?: ITableProps['headers'];
    inlaid?: boolean; // whether the parent table is inlaid in a cell.  Default: false
}

const TableHeaderRow: FC<ITableHeaderRowProps> = ({ headers, inlaid }) => {
    return headers ? (
        <tr className={inlaid ? TR_INLAID_FIRST_ROW_CLASS : undefined}>
            {headers.map(h => <th>{h}</th>)}
        </tr>
    ) : null;
}

interface ITableCell {
    entry: any;
    inlaid?: boolean; 
}
const TableCell: FC<ITableCell> = ({ entry, inlaid }) => {
    const isNull = entry === null;
    const isArray = !isNull && Array.isArray(entry);
    const isObj = !(isNull || isArray) && typeof entry === 'object';
    const isDate = isObj && entry instanceof Date;
    const isString = !(isNull || isObj || isArray || isDate) && typeof entry === 'string';
    const isDateString = isString && !isNaN(Date.parse(entry));

    const date = isDate ? entry
                : isDateString ?
                    new Date(entry) : null;

    const formattedDateString = date ? date.toLocaleString() : null;

    const paddingStyle = (isArray || isObj) ? { padding: 0 } : undefined;
    return (
        <td style={paddingStyle}>
            {isArray ?
                <Table inlaid rows={entry.map((item: any) => [item])} />
                : isObj ?
                    ( isDate ? formattedDateString : <Table inlaid rows={Object.entries(entry)} />)
                        : isNull ?
                            TABLE_CELL_NULL_PLACEHOLDER
                            : isDateString ?
                                formattedDateString
                                : isString ?
                                    entry
                                    : JSON.stringify(entry)
            }
        </td>
    );
};


const Table: FC<ITableProps> = ({ headers, rows, inlaid }) => (
    <table className={inlaid ? TABLE_INLAID_CLASS : undefined}>
        <TableHeaderRow headers={headers} inlaid={inlaid} />
        <tbody>
        {
            rows.map((row, idx) => {
                const firstRow = !headers && idx === 0;
                return (
                    <tr className={!inlaid ? undefined : (firstRow ? TR_INLAID_FR_COMBINED_CLASS: TR_INLAID_CLASS)}>
                        {row.map(entry => <TableCell entry={entry} inlaid={inlaid} />)}
                    </tr>
                );
            })
        }
        </tbody>
    </table>
);


export default Table;