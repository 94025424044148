import React, { FC, useContext, useEffect, useState } from 'react';
import SessionContext from '../contexts/SessionContext';
import { Redirect } from 'react-router';
import api from '../parts/Client/Client';

const LogoutPage: FC = () => {
    const sessionCtx = useContext(SessionContext);
    const [header, setHeader] = useState<string>('Logging Out...');

    useEffect(() => {
        api.deleteSession()
            .then(resp => {
                // Logout - SUCCESS
                const { error, success } = resp.data;
                if (success) {
                    setHeader('Logged Out.  Redirecting...');
                    sessionCtx.setAuthenticated(false);
                    sessionCtx.setAdmin(false);
                    window.location.reload();
                } else if (error) {
                    setHeader('Logout Error: ' + error);
                }
            })
            .catch(err => {
                // Logout - FAIL
                setHeader('Logout Error: ' + err.message);
            });
    }, [sessionCtx]);

    return sessionCtx.authenticated ? (
        <div className="logout-page">
            <h1>{header}</h1>
        </div>
    ) : <Redirect to="/" />
}

export default LogoutPage;