import { DefaultApi } from '../../api';

interface IBasePathEnv {
    [envName: string]: string;
}
const BASE_PATH_ENV: IBasePathEnv = {
    DEV: "http://localhost:9191/v1",
    PROD: "https://jobs-api.nyctnb.com/v1"
}

const { REACT_APP_ENV } = process.env;

const DEFAULT_BASE_PATH = BASE_PATH_ENV.PROD;
const BASE_PATH = REACT_APP_ENV && BASE_PATH_ENV[REACT_APP_ENV] ? 
                        BASE_PATH_ENV[REACT_APP_ENV] : DEFAULT_BASE_PATH;

const api = new DefaultApi({ basePath: BASE_PATH, baseOptions: { withCredentials: true } });
export default api;