import React, { FC, useContext } from 'react';
import SessionContext from '../../contexts/SessionContext';

// INFO PANEL - PERSONAL SESSION
export const PersonalSessionPanel: FC = () => {
    const sessionCtx = useContext(SessionContext);

    function yesnoStr(bool: boolean): string {
        return bool ? 'Yes' : 'No';
    }

    interface IJobsListProps {
        jobs?: string[]
    }
    
    const JobsList: FC<IJobsListProps> = ({ jobs }) => (
        jobs ? 
            <ul>{jobs.map(job => <li>{job}</li>)}</ul>
            : null
    )

    return (
        <div className="admin-home-page__info-panel">
            <h2>Personal Session Info</h2>
            <table>
                <tr><th>Field</th><th>Value</th></tr>

                <tr><td>Name</td> <td>{sessionCtx.name}</td></tr>

                <tr><td>Jobs Submitted</td><td>{sessionCtx.jobs ? <JobsList jobs={sessionCtx.jobs}/> : 'None'}</td></tr>

                <tr><td>Candidate Submitted?</td><td>{sessionCtx.candidate ? sessionCtx.candidate : 'None'}</td></tr>

                <tr><td>Authenticated</td><td>{yesnoStr(sessionCtx.authenticated)}</td></tr>

                <tr><td>Admin</td><td>{yesnoStr(sessionCtx.admin)}</td></tr>
            </table>
        </div>
    );
}

export default PersonalSessionPanel;
