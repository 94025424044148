import React, { FC } from 'react';

import './ItemBox.css';

export interface IItemBoxProps {
    hover?: boolean;
    shimmer?: boolean;
}

const ItemBox: FC<IItemBoxProps> = ({ children, hover=false, shimmer=false }) => {
    const hoverClass = hover ? 'item-box-hover' : '';
    const shimmerClass = shimmer ? 'item-box-shimmer' : '';
    return (
        <div className={`item-box ${hoverClass} ${shimmerClass}`}>
            {children}
        </div>
    );
};

export default ItemBox;