import React, { FC } from 'react';
import { RouteProps } from 'react-router';

import JobSubmitForm from '../parts/JobSubmitForm/JobSubmitForm';

interface ISubmitJobPageProps extends RouteProps {}

const SubmitJobPage: FC<ISubmitJobPageProps> = (props) => {
    return (
        <JobSubmitForm />
    )
}

export default SubmitJobPage;