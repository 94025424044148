import React, { FC, ChangeEvent, useState, useEffect } from 'react'; 
import { SessionRecordGetData } from '../../api';
import api from '../Client/Client';
import Table from '../Table/Table';
import AdminPanelFilterBox, { AdminPanelFilterBoxItem } from './AdminPanelFilterBox';
import { filterSessionRecordsByStartDate, formatDateYYYYMMDD, processSessionRecords, getUsernameSetFromSessionRecords, filterSessionRecordsByUsernameSet } from '../../util';

const DEFAULT_MIN_DATE = new Date('Feb 1 2020');
const TODAYS_DATE = new Date();
const LOCAL_TIMEZONE_OFFSET_MS = TODAYS_DATE.getTimezoneOffset()*60*1000;

// INFO PANEL - SESSION RECORDS
export const SessionRecordsPanel: FC = () => {
    // SessionRecordGetData
    const [sessionRecords, setSessionRecords] = useState<SessionRecordGetData[]>([]);
    const [usernameSet, setUsernameSet] = useState<Set<string>>(new Set());
    const [selectedUsernameSet, setSelectedUsernameSet] = useState<Set<string>>(new Set());
    const [srError, setSRError] = useState<string|null>('Loading . . .');
    const [fetchAttempted, setFetchAttempted] = useState<boolean>(false);
    const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);
    const [minStartDate, setMinStartDate] = useState<Date|null>(DEFAULT_MIN_DATE);
    const [maxStartDate, setMaxStartDate] = useState<Date|null>(TODAYS_DATE);

    useEffect(() => {
        api.getSessionRecords()
            .then((resp) => {
                setFetchAttempted(true);
                // get jobs - SUCCESS
                const { data, error } = resp.data;
                if (error) {
                    setFetchSuccess(false);
                    setSRError(error);
                } else if (data) {
                    setFetchSuccess(true);
                    // Processes the data, sort by start descending
                    const srs = processSessionRecords(data, true);
                    const unameSet = getUsernameSetFromSessionRecords(srs);
                    console.log(`unameSet: ${unameSet.toString()}`);
                    console.log(unameSet);
                    setSessionRecords(srs);
                    setUsernameSet(unameSet);
                    setSelectedUsernameSet(unameSet);
                    setSRError(null);
                } else {
                    setFetchSuccess(true);
                    setSRError('No server error but no session records data received (?)');
                }
            })
            .catch((err) => {
                // get jobs - ERROR
                setFetchAttempted(true);
                setFetchSuccess(false);
                setSRError(err.message);
            })
    }, []);

    function checkUsername(e: ChangeEvent<HTMLInputElement>) {
        const { value, checked } = e.target;
        console.log(`checkUsername: ${checked} ${value}`)
        const newSelectedUsernameSet = selectedUsernameSet;
        if (checked) {
            newSelectedUsernameSet.add(value);
        } else {
            newSelectedUsernameSet.delete(value);
        }
        setSelectedUsernameSet(newSelectedUsernameSet);
        console.log(newSelectedUsernameSet);
    }

    

    const selectedUsernameFilter = filterSessionRecordsByUsernameSet(selectedUsernameSet);
    const startDateFilter =  filterSessionRecordsByStartDate(minStartDate, maxStartDate);

    const displayedSessionRecords = sessionRecords
                                        .filter(startDateFilter)
                                        .filter(selectedUsernameFilter);

    return fetchAttempted && fetchSuccess ? (
        <div className="admin-home-page__info-panel">
            <h2 className="admin-home-page__panel-header">Session Records</h2>
            <h3 className="admin-home-page__error-message">{srError}</h3>
            <div>
                <AdminPanelFilterBox header="Start Date">
                    <AdminPanelFilterBoxItem label="Min">
                        <input
                            type="date"
                            className="admin-home-page__info-panel__min-start-date"
                            defaultValue={formatDateYYYYMMDD(minStartDate)}
                            onChange={e => {
                                setMinStartDate(e.target.valueAsDate)
                            }}
                            min="2020-02-01"
                        />
                    </AdminPanelFilterBoxItem>
                    <AdminPanelFilterBoxItem label="Max">
                        <input
                            type="date"
                            className="admin-home-page__info-panel__max-start-date"
                            defaultValue={formatDateYYYYMMDD(maxStartDate)}
                            onChange={e => {
                                const newMaxStartDate = new Date(e.target.valueAsNumber + LOCAL_TIMEZONE_OFFSET_MS);
                                if (newMaxStartDate) {
                                    newMaxStartDate.setDate(newMaxStartDate.getDate() + 1);
                                }
                                setMaxStartDate(newMaxStartDate);
                            }}
                            min="2020-02-01"
                        />
                    </AdminPanelFilterBoxItem>
                </AdminPanelFilterBox>
                {   usernameSet && usernameSet.size > 0 &&
                    <AdminPanelFilterBox header="Usernames">
                        <AdminPanelFilterBoxItem>
                            {
                                Array.from(usernameSet).sort().map(username => (
                                    <label style={{ display: 'block', textAlign: 'left' }}>
                                        <input
                                            type="checkbox"
                                            value={username}
                                            defaultChecked
                                            onChange={checkUsername}
                                        />
                                        {username}
                                        <br />
                                    </label>
                                ))
                            }
                        </AdminPanelFilterBoxItem>
                    </AdminPanelFilterBox>
                }
            </div>
            {displayedSessionRecords.length > 0 ? (
                displayedSessionRecords.map(record => (
                    <div className="admin-home-page__listed-table">
                        <h3>{record.id}</h3>
                        <h4>Stored: {(new Date(record.dateStored)).toLocaleString()}</h4>
                        <Table
                            headers={['Field', 'Value']}
                            rows={Object.entries(record.data)}
                        />
                    </div>
                    ))
            ) : (
                'No Matching Records'
            )}
        </div>
    ) : (
        <div className="admin-home-page__jobs-info">
            <h2>Session Records</h2>
            <h3 className={fetchAttempted ? '' : 'admin-home-page__error-message'}>{srError}</h3>
        </div>
    );
}

export default SessionRecordsPanel;
