import React, { FC, useState, useEffect } from 'react';
import { RouteProps }  from 'react-router';
import { Link } from 'react-router-dom';

import { processJobs, DEFAULT_PLACEHOLDER_WAIT_MS, DEFAULT_LISTING_PLACEHOLDERS } from '../util';
import { JobGetData } from '../api';
import JobListing from '../parts/JobsListing/JobsListing';
import API from '../parts/Client/Client';
import ListItemPlaceHolder from '../parts/ListItem/ListItemPlaceHolder';


interface IJobsListingPageProps extends RouteProps {}

const JobsListingPage: FC<IJobsListingPageProps> = (props) => {
    const [ jobs, setJobs ] = useState<JobGetData[]>([]);
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [mainErrorColor, setMainErrorColor] = useState('white');
    const [fetchAttempted, setFetchAttempted] = useState(false);
    const [showPlaceHolders, setShowPlaceHolders] = useState(false);

    setTimeout(() => {
        // Shows placeholders if the fetch is taking a while
        if (!fetchAttempted) {
            setShowPlaceHolders(true);
        }
    }, DEFAULT_PLACEHOLDER_WAIT_MS);

    function errorMessage(message: string) {
        setMainErrorMessage(message);
        setMainErrorColor('red');
    }

    function neutralMessage(message: string) {
        setMainErrorMessage(message);
        setMainErrorColor('white');
    }

    useEffect(() => {
        API.getJobs()
            .then(resp => {
                setFetchAttempted(true);
                const { data, error} = resp.data;
                if (error) {
                    return errorMessage(error);
                }

                if (data && data.length > 0) {
                    setJobs(processJobs(data, true));
                    setMainErrorMessage('');
                } else {
                    neutralMessage('No Jobs Yet!');
                }
            })
            .catch(err => {
                errorMessage(err.message);
            })
    }, []);

    return (
        <div className="jobs-listing-page">
            <h1>Jobs</h1>
            <Link to="/">
                <button className="button-link">Go Home</button>
            </Link>
            <h3 className="jobs-listing-page__main-error" style={{color: mainErrorColor}}>{mainErrorMessage}</h3>
            {
                fetchAttempted ?
                    <JobListing listing={jobs} />
                    :
                    (showPlaceHolders ? DEFAULT_LISTING_PLACEHOLDERS : null)
            }
        </div>
    )
}

export default JobsListingPage;