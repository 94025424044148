import React, { FC, useState, useEffect } from 'react';
import { CandidateGetData } from '../../api';
import api from '../Client/Client';
import Table from '../Table/Table';


export const CandidatesPanel: FC = () => {
    const [candidates, setCandidates] = useState<CandidateGetData[]>([]);
    const [candidatesError, setCandidatesError] = useState<string|null>('Loading . . .');
    const [fetchAttempted, setFetchAttempted] = useState<boolean>(false);
    const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);

    useEffect(() => {
        api.getCandidates()
            .then((resp) => {
                setFetchAttempted(true);
                // get candidates - SUCCESS
                const { data, error } = resp.data;
                if (error) {
                    setFetchSuccess(false);
                    setCandidatesError(error);
                } else if (data) {
                    setFetchSuccess(true);
                    setCandidates(data);
                    setCandidatesError(null);
                } else {
                    setFetchSuccess(true);
                    setCandidatesError('No server error but no candidates data received (?)');
                }
            })
            .catch((err) => {
                // get candidates - ERROR
                setFetchAttempted(true);
                setFetchSuccess(false);
                setCandidatesError(err.message);
            })
    }, []);

    return fetchAttempted && fetchSuccess ? (
        <div className="admin-home-page__info-panel">
            <h2 className="admin-home-page__panel-header">Candidates</h2>
            <h3 className="admin-home-page__error-message">{candidatesError}</h3>
            {candidates ? candidates.map(candidate => (
                <div className="admin-home-page__listed-table">
                    <h3>{candidate.name}</h3>
                    <Table headers={['Field', 'Value']} rows={Object.entries(candidate)} />
                </div>
            )) : null}
            {/*<textarea className="admin-home-page__json-textarea" value={JSON.stringify(candidates,null,4)} />*/}
        </div>
    ) : (
        <div className={fetchAttempted ? '' : 'admin-home-page__error-message'}>
            <h2>Candidates</h2>
            <h3>{candidatesError}</h3>
        </div>
    );
}

export default CandidatesPanel;
