import React, { FC } from 'react';

import ItemBox, { IItemBoxProps } from '../ItemBox/ItemBox';

import './ListItem.css';

const ListItem: FC<IItemBoxProps> = ({ children, hover=true, shimmer=false }) => (
    <div className="list-item">
        <ItemBox hover={hover} shimmer={shimmer}>
            {children}
        </ItemBox>
    </div>
)

export default ListItem;