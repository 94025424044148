import React, { FC, useState, useEffect } from "react";
import { JobGetData } from "../../api";
import api from "../Client/Client";
import Table from "../Table/Table";


export const JobsPanel: FC = () => {
    const [jobs, setJobs] = useState<JobGetData[]>();
    const [jobsError, setJobsError] = useState<string|null>('Loading . . .');
    const [fetchAttempted, setFetchAttempted] = useState<boolean>(false);
    const [fetchSuccess, setFetchSuccess] = useState<boolean>(false);

    useEffect(() => {
        api.getJobs()
            .then((resp) => {
                setFetchAttempted(true);
                // get jobs - SUCCESS
                const { data, error } = resp.data;
                if (error) {
                    setFetchSuccess(false);
                    setJobsError(error);
                } else if (data) {
                    setFetchSuccess(true);
                    setJobs(data);
                    setJobsError(null);
                } else {
                    setFetchSuccess(true);
                    setJobsError('No server error but no jobs data received (?)');
                }
            })
            .catch((err) => {
                // get jobs - ERROR
                setFetchAttempted(true);
                setFetchSuccess(false);
                setJobsError(err.message);
            })
    }, []);

    return fetchAttempted && fetchSuccess ? (
        <div className="admin-home-page__info-panel">
            <h2 className="admin-home-page__panel-header">Jobs</h2>
            <h3 className="admin-home-page__error-message">{jobsError}</h3>
            {jobs ? jobs.map(job => (
                <div className="admin-home-page__listed-table">
                    <h3>{job.jobTitle}</h3>
                    <Table headers={['Field', 'Value']} rows={Object.entries(job)} />
                </div>
            )) : null}
            {/*<textarea className="admin-home-page__json-textarea" value={JSON.stringify(jobs,null,4)} />*/}
        </div>
    ) : (
        <div className="admin-home-page__jobs-info">
            <h2>Jobs</h2>
            <h3 className={fetchAttempted ? '' : 'admin-home-page__error-message'}>{jobsError}</h3>
        </div>
    );
    
}

export default JobsPanel;
