import React, { FC, createContext, useContext, useState }  from 'react';
import { RouteProps, Route, RouteComponentProps, Redirect, useLocation } from 'react-router';
import { PublicSessionData, PublicSessionUserData } from '../api';

// ISessionContext Spreads PublicSessionData's child objects out onto the top level
export interface ISessionContext extends PublicSessionUserData {
    setAuthenticated: (isAuth: boolean) => void;
    setAdmin: (isAdmin: boolean) => void;
    setName: (name: string) => void;
    setCandidate: (candidate?: string) => void;
    setJobs: (name: string[]) => void;
    fetched: boolean;
    setFetched: (isFetched: boolean) => void;
}

// TODO: remove
/*export interface ISessionContext extends PublicSessionData {
    user: ISessionUserContext;
    fetched: boolean;
    setFetched: (isFetched: boolean) => void;
}

const SESSION_CONTEXT_DEFAULT: ISessionContext = {
    user: {
        authenticated: false,
        setAuthenticated: (isAuth: boolean) => null,
        admin: false,
        setAdmin: (isAdmin: boolean) => null,
        // default NO name set (name?)
        setName: (name: string) => null,
        // default NO candidate set (candidate?)
        setCandidate: (candidate?: string) => null,
        // NO jobs set default (jobs?)
        setJobs: (name: string[]) => null
    },
    fetched: false,
    setFetched: (isFetched: boolean) => null
};*/

const SESSION_CONTEXT_DEFAULT: ISessionContext = {
    authenticated: false,
    setAuthenticated: (isAuth: boolean) => null,
    admin: false,
    setAdmin: (isAdmin: boolean) => null,
    // default NO name set (name?)
    setName: (name: string) => null,
    // default NO candidate set (candidate?)
    setCandidate: (candidate?: string) => null,
    // NO jobs set default (jobs?)
    setJobs: (name: string[]) => null,

    fetched: false,
    setFetched: (isFetched: boolean) => null
};

const SessionContext = createContext<ISessionContext>(SESSION_CONTEXT_DEFAULT);
export default SessionContext;

interface ISessionContextProviderProps {
    initialSessionData?: PublicSessionData;
}

export const SessionContextProvider: FC<ISessionContextProviderProps> = ({ initialSessionData, children }) => {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [admin, setAdmin] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [candidate, setCandidate] = useState<string>();
    const [jobs, setJobs] = useState<string[]>();
    const [fetched, setFetched] = useState<boolean>(false);

    const SESSION_CONTEXT_DEFAULT: ISessionContext = {
        authenticated, setAuthenticated,
        admin, setAdmin,
        name, setName,
        candidate, setCandidate,
        jobs, setJobs,
        fetched, setFetched
    }

    // TODO: remove
    // Deep merging because of nested objects
    /*const SESSION_CONTEXT_NEW: ISessionContext = initialSessionData ? {
        ...SESSION_CONTEXT_DEFAULT, user: {
            ...SESSION_CONTEXT_DEFAULT.user, ...initialSessionData.user
        }
    } : SESSION_CONTEXT_DEFAULT;*/

    const SESSION_CONTEXT_NEW: ISessionContext = initialSessionData ? {
        ...SESSION_CONTEXT_DEFAULT, ...initialSessionData
    } : SESSION_CONTEXT_DEFAULT;

    return (
        <SessionContext.Provider value={SESSION_CONTEXT_NEW}>
            {children}
        </SessionContext.Provider>
    )
}


interface IAuthRouteProps extends RouteProps {
    childComponent: FC<RouteProps> | FC<RouteComponentProps<any>>;
}

export const AuthRoute: FC<IAuthRouteProps> = ({ childComponent, ...rest }) => {
    // Route that redirects to the password wall if they are not logged in
    const sessionContext = useContext(SessionContext);
    const Component = childComponent;

    const loc = useLocation();

    return <Route
            {...rest}
            render={(routeProps) => sessionContext.authenticated ?
                <Component {...routeProps}/>
                : <Redirect to={{
                    pathname: "/wall",
                    state: { referrer: loc.pathname }
                }} />
            }
            />
}

interface IAdminRouteProps extends RouteProps {
    childComponent: FC<RouteProps> | FC<RouteComponentProps<any>>;
}

export const AdminRoute: FC<IAdminRouteProps> = ({ childComponent, ...rest }) => {
    // Route that redirects to the password wall if they are not logged in,
    //  or to the home page if they are not admin
    const sessionContext = useContext(SessionContext);
    const Component = childComponent;

    const loc = useLocation();

    return <Route
            {...rest}
            render={(routeProps) => sessionContext.authenticated ?
                (sessionContext.admin ?
                    <Component {...routeProps}/> : <Redirect to="/" />
                )
                : <Redirect to={{
                    pathname: "/wall",
                    state: { referrer: loc.pathname }
                }}/>
            }
            />
}