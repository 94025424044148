import React from 'react';
import ListItemPlaceHolder from './parts/ListItem/ListItemPlaceHolder';
import { CandidateGetData, JobGetData, SessionRecordGetAll, SessionRecordGetData } from './api';

export const FULL_NAME_REGEX_STR = "^[A-Za-z ,.'-]+$";

// Default wait before displaying an item placeholder while waiting for fetch
export const DEFAULT_PLACEHOLDER_WAIT_MS = 250;
export const DEFAULT_LISTING_PLACEHOLDERS = [1,1,1,1].map((_, idx) => <ListItemPlaceHolder key={idx}/>);

export function stripPhoneNumber(phoneStr: string) {
    return phoneStr.trim().replace(/[^\d]/g, '');
}

export function prettifyPhoneNumber(strippedNumber: string) {
    if (strippedNumber === '') {
        return '';
    }
    if (strippedNumber.length <= 10) {
        return '(' + strippedNumber.slice(0,3) + ') ' + strippedNumber.slice(3,6) + '-' + strippedNumber.slice(6);
    } else {
        return '+' + strippedNumber;
    }
}

export function cleanUrl(urlString: string) {
    if (!/^https?:\/\//i.test(urlString)) {
        urlString = 'https://' + urlString;
    }
    return urlString;
}

// red asterisk - indicates required fields
export const RA = (<span style={{color: 'red'}}>*</span>);

// Date string format util
export function formatDateYYYYMMDD(date: Date|null): string {
    if (date === null) return '';
    let d = new Date(date),
        month = String(d.getMonth() + 1).padStart(2, '0'),
        day = String(d.getDate()).padStart(2, '0'),
        year = d.getFullYear();

    const str = [year, month, day].join('-').padStart(10, '0');
    console.log(`formatted date: ${str}`);
    return str;
}


// Candidates GET processing
export function processCandidates(candidates: CandidateGetData[], sortDescending=false) {
    candidates = candidates.reduce<CandidateGetData[]>((runningCandidates, currentCandidate) => {
                    currentCandidate.dateCreated = new Date(currentCandidate.dateCreated);
                    runningCandidates.push(currentCandidate);
                    return runningCandidates;
                }, [])
    if (sortDescending) {
        // Sorts by date created - descending order
        candidates = candidates.sort((cA,cB) => {
            return cB.dateCreated.getTime() - cA.dateCreated.getTime();
        });
    }
    return candidates;
}

// Jobs GET processing
export function processJobs(jobs: JobGetData[], sortDescending=false) {
    jobs = jobs.reduce<JobGetData[]>((runningJobs, currentJob) => {
                currentJob.dateCreated = new Date(currentJob.dateCreated);
                runningJobs.push(currentJob)
                return runningJobs;
            }, [])
    if (sortDescending) {
        // Sorts by date created - descending order
        jobs = jobs.sort((jA,jB) => {
            return jB.dateCreated.getTime() - jA.dateCreated.getTime();
        });
    }
    return jobs;
}

// SessionRecordGetData processing
export function processSessionRecords(sds: SessionRecordGetData[], sortByStartDescending=false) {
    sds = sds.map(sd => {
        sd.dateStored = new Date(sd.dateStored);

        const { start, lastSessionCheck } = sd.data.priv;
        sd.data.priv.start = new Date(start);
        sd.data.priv.lastSessionCheck = lastSessionCheck ? new Date(lastSessionCheck) : undefined;

        return sd;
    });

    if (sortByStartDescending) {
        sds = sds.sort(sortSessionRecordsByStartDate(true));
    }
    
    return sds;
}

export function sortSessionRecordsByStartDate(desc=true): (s1: SessionRecordGetData, s2: SessionRecordGetData) => number {
    return (s1: SessionRecordGetData, s2: SessionRecordGetData) => {
        const start1 = new Date(s1.data.priv.start);
        const start2 = new Date(s2.data.priv.start);
        return desc ? (
            start2.getTime() - start1.getTime()
        ) : (
            start1.getTime() - start2.getTime()
        );
    };
}

export function filterSessionRecordsByStartDate(min: Date|null, max: Date|null): (sr: SessionRecordGetData) => boolean {
    return (sr: SessionRecordGetData) => {
        const { start } = sr.data.priv;

        return (!min || start >= min) && (!max || start < max);
    };
}

export function getUsernameSetFromSessionRecords(srs: SessionRecordGetData[]): Set<string> {
    return srs.reduce<Set<string>>((acc, sr) => {
        if (sr.data.pub.user.name) {
            acc.add(sr.data.pub.user.name);
        }
        return acc;
    }, new Set());
}

export function filterSessionRecordsByUsernameSet(usernameSet: Set<string>): (sr: SessionRecordGetData) => boolean {
    console.log(`filter username: ${Array.from(usernameSet).join(', ')}`);
    console.log(usernameSet);
    return (sr: SessionRecordGetData) => {
        const val = !!sr.data.pub.user.name && usernameSet.has(sr.data.pub.user.name);
        return val;
    };
}