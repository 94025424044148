import React, { FC } from 'react';
import { RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import JobBoardLogo from '../parts/JobBoardLogo/JobBoardLogo';

interface IExplanationPageProps extends RouteProps {}

const ExplanationPage: FC<IExplanationPageProps> = (props) => {
    return (
        <div>
            <JobBoardLogo />
            <p>This is the Job Board for <a href="https://nyctnb.com" target="_blank">New York Tech &amp; Beer</a>.</p>
            <p>This is where our members connect with other members to find and refer jobs and software projects.</p>
            <p>A weekly password is given out at <a href="https://meetup.com/NYCTnB">our events</a> so that members can connect with other members they've met.</p>
            <p>If you're looking for work, or to hire talented developers in New York City, join us at the events linked above and you will be provided a password at the event.</p>
            <p>We hope to meet you soon!</p>

            <Link to="/">
                <button className="button-link">Home</button>
            </Link>
        </div>
    )
}

export default ExplanationPage;