// tslint:disable
/**
 * NYCTnB Jobs Board API
 * The data and session REST API for the NYCTnB Jobs Board.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CandidateGet
 */
export interface CandidateGet {
    /**
     * 
     * @type {string}
     * @memberof CandidateGet
     */
    error?: string;
    /**
     * 
     * @type {CandidateGetData}
     * @memberof CandidateGet
     */
    data?: CandidateGetData;
}
/**
 * 
 * @export
 * @interface CandidateGetAll
 */
export interface CandidateGetAll {
    /**
     * 
     * @type {string}
     * @memberof CandidateGetAll
     */
    error?: string;
    /**
     * 
     * @type {Array<CandidateGetData>}
     * @memberof CandidateGetAll
     */
    data?: Array<CandidateGetData>;
}
/**
 * 
 * @export
 * @interface CandidateGetAllAllOf
 */
export interface CandidateGetAllAllOf {
    /**
     * 
     * @type {Array<CandidateGetData>}
     * @memberof CandidateGetAllAllOf
     */
    data?: Array<CandidateGetData>;
}
/**
 * 
 * @export
 * @interface CandidateGetAllOf
 */
export interface CandidateGetAllOf {
    /**
     * 
     * @type {CandidateGetData}
     * @memberof CandidateGetAllOf
     */
    data?: CandidateGetData;
}
/**
 * 
 * @export
 * @interface CandidateGetData
 */
export interface CandidateGetData {
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    tagline: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateGetData
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateGetData
     */
    dateCreated: Date;
}
/**
 * 
 * @export
 * @interface CandidateOptional
 */
export interface CandidateOptional {
    /**
     * 
     * @type {string}
     * @memberof CandidateOptional
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateOptional
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateOptional
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface CandidatePost
 */
export interface CandidatePost {
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    tagline: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatePost
     */
    website?: string;
}
/**
 * 
 * @export
 * @interface CandidatePostResponse
 */
export interface CandidatePostResponse extends IDPostResponse {
}
/**
 * 
 * @export
 * @interface CandidateRequired
 */
export interface CandidateRequired {
    /**
     * 
     * @type {string}
     * @memberof CandidateRequired
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateRequired
     */
    tagline: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateRequired
     */
    description: string;
}
/**
 * 
 * @export
 * @interface DataErrorJsonResponse
 */
export interface DataErrorJsonResponse {
    /**
     * 
     * @type {string}
     * @memberof DataErrorJsonResponse
     */
    error?: string;
    /**
     * 
     * @type {object}
     * @memberof DataErrorJsonResponse
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface IDPostResponse
 */
export interface IDPostResponse {
    /**
     * 
     * @type {string}
     * @memberof IDPostResponse
     */
    error?: string;
    /**
     * 
     * @type {IDPostResponseAllOfData}
     * @memberof IDPostResponse
     */
    data?: IDPostResponseAllOfData;
}
/**
 * 
 * @export
 * @interface IDPostResponseAllOf
 */
export interface IDPostResponseAllOf {
    /**
     * 
     * @type {IDPostResponseAllOfData}
     * @memberof IDPostResponseAllOf
     */
    data?: IDPostResponseAllOfData;
}
/**
 * 
 * @export
 * @interface IDPostResponseAllOfData
 */
export interface IDPostResponseAllOfData {
    /**
     * 
     * @type {string}
     * @memberof IDPostResponseAllOfData
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface JobGet
 */
export interface JobGet {
    /**
     * 
     * @type {string}
     * @memberof JobGet
     */
    error?: string;
    /**
     * 
     * @type {JobGetData}
     * @memberof JobGet
     */
    data?: JobGetData;
}
/**
 * 
 * @export
 * @interface JobGetAll
 */
export interface JobGetAll {
    /**
     * 
     * @type {string}
     * @memberof JobGetAll
     */
    error?: string;
    /**
     * 
     * @type {Array<JobGetData>}
     * @memberof JobGetAll
     */
    data?: Array<JobGetData>;
}
/**
 * 
 * @export
 * @interface JobGetAllAllOf
 */
export interface JobGetAllAllOf {
    /**
     * 
     * @type {Array<JobGetData>}
     * @memberof JobGetAllAllOf
     */
    data?: Array<JobGetData>;
}
/**
 * 
 * @export
 * @interface JobGetAllOf
 */
export interface JobGetAllOf {
    /**
     * 
     * @type {JobGetData}
     * @memberof JobGetAllOf
     */
    data?: JobGetData;
}
/**
 * 
 * @export
 * @interface JobGetData
 */
export interface JobGetData {
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof JobGetData
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof JobGetData
     */
    dateCreated: Date;
}
/**
 * 
 * @export
 * @interface JobGetDataAllOf
 */
export interface JobGetDataAllOf {
    /**
     * 
     * @type {string}
     * @memberof JobGetDataAllOf
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof JobGetDataAllOf
     */
    dateCreated: Date;
}
/**
 * 
 * @export
 * @interface JobOptional
 */
export interface JobOptional {
    /**
     * 
     * @type {string}
     * @memberof JobOptional
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOptional
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobOptional
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface JobPost
 */
export interface JobPost {
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JobPost
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface JobPostResponse
 */
export interface JobPostResponse extends IDPostResponse {
}
/**
 * 
 * @export
 * @interface JobRequired
 */
export interface JobRequired {
    /**
     * 
     * @type {string}
     * @memberof JobRequired
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequired
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequired
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof JobRequired
     */
    description: string;
}
/**
 * 
 * @export
 * @interface PrivateSessionData
 */
export interface PrivateSessionData {
    /**
     * The date-time the session first began
     * @type {Date}
     * @memberof PrivateSessionData
     */
    start: Date;
    /**
     * The date-time that the last public session GET was performed (inidicative of the last time they first opened the app)
     * @type {Date}
     * @memberof PrivateSessionData
     */
    lastSessionCheck?: Date;
    /**
     * 
     * @type {PublicSessionUserData}
     * @memberof PrivateSessionData
     */
    puser?: PublicSessionUserData;
}
/**
 * 
 * @export
 * @interface PublicSessionData
 */
export interface PublicSessionData {
    /**
     * 
     * @type {PublicSessionUserData}
     * @memberof PublicSessionData
     */
    user: PublicSessionUserData;
}
/**
 * 
 * @export
 * @interface PublicSessionPost
 */
export interface PublicSessionPost {
    /**
     * 
     * @type {string}
     * @memberof PublicSessionPost
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PublicSessionPost
     */
    name: string;
}
/**
 * 
 * @export
 * @interface PublicSessionPostResponse
 */
export interface PublicSessionPostResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicSessionPostResponse
     */
    error?: string;
    /**
     * 
     * @type {PublicSessionData}
     * @memberof PublicSessionPostResponse
     */
    data?: PublicSessionData;
}
/**
 * 
 * @export
 * @interface PublicSessionPostResponseAllOf
 */
export interface PublicSessionPostResponseAllOf {
    /**
     * 
     * @type {PublicSessionData}
     * @memberof PublicSessionPostResponseAllOf
     */
    data?: PublicSessionData;
}
/**
 * 
 * @export
 * @interface PublicSessionUserData
 */
export interface PublicSessionUserData {
    /**
     * 
     * @type {boolean}
     * @memberof PublicSessionUserData
     */
    authenticated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicSessionUserData
     */
    admin: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicSessionUserData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicSessionUserData
     */
    candidate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicSessionUserData
     */
    jobs?: Array<string>;
}
/**
 * 
 * @export
 * @interface SessionRecordGet
 */
export interface SessionRecordGet {
    /**
     * 
     * @type {string}
     * @memberof SessionRecordGet
     */
    error?: string;
    /**
     * 
     * @type {SessionRecordGetData}
     * @memberof SessionRecordGet
     */
    data?: SessionRecordGetData;
}
/**
 * 
 * @export
 * @interface SessionRecordGetAll
 */
export interface SessionRecordGetAll {
    /**
     * 
     * @type {string}
     * @memberof SessionRecordGetAll
     */
    error?: string;
    /**
     * 
     * @type {Array<SessionRecordGetData>}
     * @memberof SessionRecordGetAll
     */
    data?: Array<SessionRecordGetData>;
}
/**
 * 
 * @export
 * @interface SessionRecordGetAllAllOf
 */
export interface SessionRecordGetAllAllOf {
    /**
     * 
     * @type {Array<SessionRecordGetData>}
     * @memberof SessionRecordGetAllAllOf
     */
    data?: Array<SessionRecordGetData>;
}
/**
 * 
 * @export
 * @interface SessionRecordGetAllOf
 */
export interface SessionRecordGetAllOf {
    /**
     * 
     * @type {SessionRecordGetData}
     * @memberof SessionRecordGetAllOf
     */
    data?: SessionRecordGetData;
}
/**
 * 
 * @export
 * @interface SessionRecordGetData
 */
export interface SessionRecordGetData {
    /**
     * 
     * @type {string}
     * @memberof SessionRecordGetData
     */
    id: string;
    /**
     * 
     * @type {TotalSessionData}
     * @memberof SessionRecordGetData
     */
    data: TotalSessionData;
    /**
     * 
     * @type {Date}
     * @memberof SessionRecordGetData
     */
    dateStored: Date;
}
/**
 * 
 * @export
 * @interface SessionRecordGetDataAllOf
 */
export interface SessionRecordGetDataAllOf {
    /**
     * 
     * @type {string}
     * @memberof SessionRecordGetDataAllOf
     */
    id: string;
    /**
     * 
     * @type {TotalSessionData}
     * @memberof SessionRecordGetDataAllOf
     */
    data: TotalSessionData;
    /**
     * 
     * @type {Date}
     * @memberof SessionRecordGetDataAllOf
     */
    dateStored: Date;
}
/**
 * 
 * @export
 * @interface SessionRecordSaveData
 */
export interface SessionRecordSaveData {
    /**
     * 
     * @type {string}
     * @memberof SessionRecordSaveData
     */
    id: string;
    /**
     * 
     * @type {TotalSessionData | string}
     * @memberof SessionRecordSaveData
     */
    data: TotalSessionData | string;
}
/**
 * 
 * @export
 * @interface SuccessErrorJsonResponse
 */
export interface SuccessErrorJsonResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessErrorJsonResponse
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessErrorJsonResponse
     */
    error?: string;
}
/**
 * 
 * @export
 * @interface TotalSessionData
 */
export interface TotalSessionData {
    /**
     * 
     * @type {PublicSessionData}
     * @memberof TotalSessionData
     */
    pub: PublicSessionData;
    /**
     * 
     * @type {PrivateSessionData}
     * @memberof TotalSessionData
     */
    priv: PrivateSessionData;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post a new candidate listing
         * @param {CandidatePost} candidatePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidate(candidatePost: CandidatePost, options: any = {}): RequestArgs {
            // verify required parameter 'candidatePost' is not null or undefined
            if (candidatePost === null || candidatePost === undefined) {
                throw new RequiredError('candidatePost','Required parameter candidatePost was null or undefined when calling createCandidate.');
            }
            const localVarPath = `/candidates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof candidatePost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(candidatePost !== undefined ? candidatePost : {}) : (candidatePost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a new job listing
         * @param {JobPost} jobPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob(jobPost: JobPost, options: any = {}): RequestArgs {
            // verify required parameter 'jobPost' is not null or undefined
            if (jobPost === null || jobPost === undefined) {
                throw new RequiredError('jobPost','Required parameter jobPost was null or undefined when calling createJob.');
            }
            const localVarPath = `/jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof jobPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(jobPost !== undefined ? jobPost : {}) : (jobPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit login credentials to initiate a session.
         * @param {PublicSessionPost} publicSessionPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(publicSessionPost: PublicSessionPost, options: any = {}): RequestArgs {
            // verify required parameter 'publicSessionPost' is not null or undefined
            if (publicSessionPost === null || publicSessionPost === undefined) {
                throw new RequiredError('publicSessionPost','Required parameter publicSessionPost was null or undefined when calling createSession.');
            }
            const localVarPath = `/session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof publicSessionPost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(publicSessionPost !== undefined ? publicSessionPost : {}) : (publicSessionPost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a candidate by its ID.
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidate(candidateId: string, options: any = {}): RequestArgs {
            // verify required parameter 'candidateId' is not null or undefined
            if (candidateId === null || candidateId === undefined) {
                throw new RequiredError('candidateId','Required parameter candidateId was null or undefined when calling deleteCandidate.');
            }
            const localVarPath = `/candidates/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a job by its ID.
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob(jobId: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling deleteJob.');
            }
            const localVarPath = `/jobs/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete current session (logout)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options: any = {}): RequestArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for a candidate by its UUID
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateById(candidateId: string, options: any = {}): RequestArgs {
            // verify required parameter 'candidateId' is not null or undefined
            if (candidateId === null || candidateId === undefined) {
                throw new RequiredError('candidateId','Required parameter candidateId was null or undefined when calling getCandidateById.');
            }
            const localVarPath = `/candidates/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all candidate listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidates(offset?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/candidates`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get data for a job by its UUID
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById(jobId: string, options: any = {}): RequestArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling getJobById.');
            }
            const localVarPath = `/jobs/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all jobs listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session authenticated status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options: any = {}): RequestArgs {
            const localVarPath = `/session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a session record by its UUID
         * @param {string} sessionRecordId UUID of the session record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecordById(sessionRecordId: string, options: any = {}): RequestArgs {
            // verify required parameter 'sessionRecordId' is not null or undefined
            if (sessionRecordId === null || sessionRecordId === undefined) {
                throw new RequiredError('sessionRecordId','Required parameter sessionRecordId was null or undefined when calling getSessionRecordById.');
            }
            const localVarPath = `/admin/sessionRecords/{sessionRecordId}`
                .replace(`{${"sessionRecordId"}}`, encodeURIComponent(String(sessionRecordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session records
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecords(offset?: number, limit?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/sessionRecords`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post a new candidate listing
         * @param {CandidatePost} candidatePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidate(candidatePost: CandidatePost, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidatePostResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createCandidate(candidatePost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Post a new job listing
         * @param {JobPost} jobPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob(jobPost: JobPost, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobPostResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createJob(jobPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submit login credentials to initiate a session.
         * @param {PublicSessionPost} publicSessionPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(publicSessionPost: PublicSessionPost, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSessionPostResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createSession(publicSessionPost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a candidate by its ID.
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidate(candidateId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessErrorJsonResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteCandidate(candidateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a job by its ID.
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob(jobId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessErrorJsonResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteJob(jobId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete current session (logout)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessErrorJsonResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get data for a candidate by its UUID
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateById(candidateId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateGet> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCandidateById(candidateId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all candidate listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidates(offset?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateGetAll> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getCandidates(offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get data for a job by its UUID
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById(jobId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobGet> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getJobById(jobId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns all jobs listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobGetAll> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getJobs(offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get session authenticated status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSessionData> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSession(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a session record by its UUID
         * @param {string} sessionRecordId UUID of the session record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecordById(sessionRecordId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionRecordGet> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSessionRecordById(sessionRecordId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get session records
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecords(offset?: number, limit?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionRecordGetAll> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getSessionRecords(offset, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Post a new candidate listing
         * @param {CandidatePost} candidatePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidate(candidatePost: CandidatePost, options?: any): AxiosPromise<CandidatePostResponse> {
            return DefaultApiFp(configuration).createCandidate(candidatePost, options)(axios, basePath);
        },
        /**
         * 
         * @summary Post a new job listing
         * @param {JobPost} jobPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob(jobPost: JobPost, options?: any): AxiosPromise<JobPostResponse> {
            return DefaultApiFp(configuration).createJob(jobPost, options)(axios, basePath);
        },
        /**
         * 
         * @summary Submit login credentials to initiate a session.
         * @param {PublicSessionPost} publicSessionPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(publicSessionPost: PublicSessionPost, options?: any): AxiosPromise<PublicSessionPostResponse> {
            return DefaultApiFp(configuration).createSession(publicSessionPost, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a candidate by its ID.
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidate(candidateId: string, options?: any): AxiosPromise<SuccessErrorJsonResponse> {
            return DefaultApiFp(configuration).deleteCandidate(candidateId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a job by its ID.
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob(jobId: string, options?: any): AxiosPromise<SuccessErrorJsonResponse> {
            return DefaultApiFp(configuration).deleteJob(jobId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete current session (logout)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(options?: any): AxiosPromise<SuccessErrorJsonResponse> {
            return DefaultApiFp(configuration).deleteSession(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get data for a candidate by its UUID
         * @param {string} candidateId UUID of the candidate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateById(candidateId: string, options?: any): AxiosPromise<CandidateGet> {
            return DefaultApiFp(configuration).getCandidateById(candidateId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns all candidate listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidates(offset?: number, limit?: number, options?: any): AxiosPromise<CandidateGetAll> {
            return DefaultApiFp(configuration).getCandidates(offset, limit, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get data for a job by its UUID
         * @param {string} jobId UUID of the job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById(jobId: string, options?: any): AxiosPromise<JobGet> {
            return DefaultApiFp(configuration).getJobById(jobId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns all jobs listings
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(offset?: number, limit?: number, options?: any): AxiosPromise<JobGetAll> {
            return DefaultApiFp(configuration).getJobs(offset, limit, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get session authenticated status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: any): AxiosPromise<PublicSessionData> {
            return DefaultApiFp(configuration).getSession(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a session record by its UUID
         * @param {string} sessionRecordId UUID of the session record
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecordById(sessionRecordId: string, options?: any): AxiosPromise<SessionRecordGet> {
            return DefaultApiFp(configuration).getSessionRecordById(sessionRecordId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get session records
         * @param {number} [offset] 
         * @param {number} [limit] The number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionRecords(offset?: number, limit?: number, options?: any): AxiosPromise<SessionRecordGetAll> {
            return DefaultApiFp(configuration).getSessionRecords(offset, limit, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Post a new candidate listing
     * @param {CandidatePost} candidatePost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createCandidate(candidatePost: CandidatePost, options?: any): AxiosPromise<CandidatePostResponse>;

    /**
     * 
     * @summary Post a new job listing
     * @param {JobPost} jobPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createJob(jobPost: JobPost, options?: any): AxiosPromise<JobPostResponse>;

    /**
     * 
     * @summary Submit login credentials to initiate a session.
     * @param {PublicSessionPost} publicSessionPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createSession(publicSessionPost: PublicSessionPost, options?: any): AxiosPromise<PublicSessionPostResponse>;

    /**
     * 
     * @summary Delete a candidate by its ID.
     * @param {string} candidateId UUID of the candidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteCandidate(candidateId: string, options?: any): AxiosPromise<SuccessErrorJsonResponse>;

    /**
     * 
     * @summary Delete a job by its ID.
     * @param {string} jobId UUID of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteJob(jobId: string, options?: any): AxiosPromise<SuccessErrorJsonResponse>;

    /**
     * 
     * @summary Delete current session (logout)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteSession(options?: any): AxiosPromise<SuccessErrorJsonResponse>;

    /**
     * 
     * @summary Get data for a candidate by its UUID
     * @param {string} candidateId UUID of the candidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCandidateById(candidateId: string, options?: any): AxiosPromise<CandidateGet>;

    /**
     * 
     * @summary Returns all candidate listings
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCandidates(offset?: number, limit?: number, options?: any): AxiosPromise<CandidateGetAll>;

    /**
     * 
     * @summary Get data for a job by its UUID
     * @param {string} jobId UUID of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getJobById(jobId: string, options?: any): AxiosPromise<JobGet>;

    /**
     * 
     * @summary Returns all jobs listings
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getJobs(offset?: number, limit?: number, options?: any): AxiosPromise<JobGetAll>;

    /**
     * 
     * @summary Get session authenticated status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSession(options?: any): AxiosPromise<PublicSessionData>;

    /**
     * 
     * @summary Get a session record by its UUID
     * @param {string} sessionRecordId UUID of the session record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSessionRecordById(sessionRecordId: string, options?: any): AxiosPromise<SessionRecordGet>;

    /**
     * 
     * @summary Get session records
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSessionRecords(offset?: number, limit?: number, options?: any): AxiosPromise<SessionRecordGetAll>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Post a new candidate listing
     * @param {CandidatePost} candidatePost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCandidate(candidatePost: CandidatePost, options?: any) {
        return DefaultApiFp(this.configuration).createCandidate(candidatePost, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Post a new job listing
     * @param {JobPost} jobPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createJob(jobPost: JobPost, options?: any) {
        return DefaultApiFp(this.configuration).createJob(jobPost, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Submit login credentials to initiate a session.
     * @param {PublicSessionPost} publicSessionPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSession(publicSessionPost: PublicSessionPost, options?: any) {
        return DefaultApiFp(this.configuration).createSession(publicSessionPost, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a candidate by its ID.
     * @param {string} candidateId UUID of the candidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCandidate(candidateId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteCandidate(candidateId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a job by its ID.
     * @param {string} jobId UUID of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJob(jobId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJob(jobId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete current session (logout)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSession(options?: any) {
        return DefaultApiFp(this.configuration).deleteSession(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get data for a candidate by its UUID
     * @param {string} candidateId UUID of the candidate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCandidateById(candidateId: string, options?: any) {
        return DefaultApiFp(this.configuration).getCandidateById(candidateId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns all candidate listings
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCandidates(offset?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).getCandidates(offset, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get data for a job by its UUID
     * @param {string} jobId UUID of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJobById(jobId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJobById(jobId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns all jobs listings
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJobs(offset?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).getJobs(offset, limit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get session authenticated status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSession(options?: any) {
        return DefaultApiFp(this.configuration).getSession(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a session record by its UUID
     * @param {string} sessionRecordId UUID of the session record
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSessionRecordById(sessionRecordId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSessionRecordById(sessionRecordId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get session records
     * @param {number} [offset] 
     * @param {number} [limit] The number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSessionRecords(offset?: number, limit?: number, options?: any) {
        return DefaultApiFp(this.configuration).getSessionRecords(offset, limit, options)(this.axios, this.basePath);
    }

}


