import React, { FC, useState, useEffect, useContext } from 'react';
import JobListItem from './JobListItem';
import API from '../Client/Client';

import { JobGetData } from '../../api';
import SessionContext from '../../contexts/SessionContext';

interface IJobListing {
    listing: JobGetData[];
}

export const JobsListing: FC<IJobListing> = (props) => {
    const sessionCtx = useContext(SessionContext);
    const ownedJobsSet = sessionCtx.jobs ? new Set(sessionCtx.jobs) : new Set([]);

    const [listing, setListing] = useState<JobGetData[]>(props.listing);

    useEffect(() => {
        setListing(props.listing);
    }, [props.listing]);

    function deleteJobCB(jobId: string) {
        const jobIdx = listing.findIndex(job => (job.id === jobId));
        const job = listing[jobIdx];
        if(window.confirm(`Are you sure you want to delete job '${job.jobTitle}'?`)) {
            API.deleteJob(jobId)
                .then(resp => {
                    const { error, success } = resp.data;
                    if (success) {
                        // delete SUCCESS
                        // Removing the job from the listing
                        const removedJob = listing.splice(jobIdx, 1);
                        setListing([...listing]);
                        if (sessionCtx.jobs && sessionCtx.jobs.length > 0) {
                            sessionCtx.jobs.splice(sessionCtx.jobs.findIndex(sJobId => sJobId === jobId));
                            sessionCtx.setJobs(sessionCtx.jobs);
                        }
                    } else {
                        // delete FAIL
                        alert(`Job Delete Failure: ${error}`);
                    }
                })
                .catch(err => {
                    // delete FAIL
                    alert(`Job Delete Failure: ${err.message}`);
                })
        }
    }

    return (
        <div className="jobs-listing">
        {
            listing.map((job) =>
                <JobListItem
                    {...job}
                    key={job.id}
                    deleteCB={ownedJobsSet.has(job.id) ? deleteJobCB : undefined}
                />)
        }
        </div>
    )
}

export default JobsListing;