import React, { FC, useState, FormEvent, useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { JobPost, JobGetData, JobPostResponse } from '../../api';
import API from '../Client/Client';
import {prettifyPhoneNumber, stripPhoneNumber, cleanUrl, RA, FULL_NAME_REGEX_STR } from '../../util';

import './JobSubmitForm.css';
import SessionContext from '../../contexts/SessionContext';
import api from '../Client/Client';

const MAX_FIELD_LENGTH = {
    jobTitle: 45,
    companyName: 80,
    contactName: 35,
    description: 200,
    email: 80,
    phoneNumber: 15,
    link: 150
};

interface IJobSubmitFormProps {}

const JobSubmitForm: FC = (props: IJobSubmitFormProps) => {
    const sessionCtx = useContext(SessionContext);

    // Required fields
    const [jobTitle, setJobTitle] = useState<JobPost['jobTitle']>('');
    const [companyName, setCompanyName] = useState<JobPost['companyName']>('');
    const [contactName, setContactName] = useState<JobPost['contactName']>('');
    const [description, setDescription] = useState<JobPost['description']>('');
    // Optional fields
    const [email, setEmail] = useState<JobPost['email']>('');
    const [phoneNumber, setPhoneNumber] = useState<JobPost['phoneNumber']>('');
    const [link, setLink] = useState<JobPost['link']>('');

    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState('');
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [mainErrorMessageColor, setMainErrorMessageColor] = useState('black');

    const [inputsDisabled, setInputsDisabled] = useState(false);

    // ID of job when successfull submitted
    const [jobSubmittedID, setJobSubmittedID] = useState<string>();

    function setBadError(errMessage: string) {
        setMainErrorMessage(errMessage);
        setMainErrorMessageColor('red');
    }

    function formSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setInputsDisabled(true);

        const submitData: JobPost = Object.assign({},
            {jobTitle,companyName,contactName,description},
            email && email.length > 0 ? {email} : {},
            phoneNumber && phoneNumber.length > 0 ? {phoneNumber} : {},
            link && link.length > 0 ? {link: cleanUrl(link)} : {}
        );

        if (!validFieldValues(submitData)) {
            return console.error('Invalid form inputs.');
        }

        API.createJob(submitData)
            .then(resp => {
                const { data, error } = resp.data;
                // TODO: handle successful submit
                if (error) {
                    setMainErrorMessageColor('red');
                    setMainErrorMessage(error);
                    setInputsDisabled(false);
                } else if (data && data.id) {
                    setMainErrorMessageColor('green');
                    setMainErrorMessage('Job submitted!');
                    window.scrollTo({ top: 0, behavior: 'smooth'});
                    // Adding job ID to session record
                    sessionCtx.setJobs(sessionCtx.jobs ? [...sessionCtx.jobs,data.id] : [data.id]);
                    setJobSubmittedID(data.id);
                }
            })
            .catch(err => {
                // TODO: handle error
                setMainErrorMessage(err.message);
                setMainErrorMessageColor('red');
                setInputsDisabled(false);
            })
    }

    function updatePhoneNumber(phoneInput: string) {
        const strippedNumber = stripPhoneNumber(phoneInput);
        if (strippedNumber.length > MAX_FIELD_LENGTH.phoneNumber) {
            return;
        }
        setPhoneNumber(strippedNumber);
        setPhoneNumberDisplay(prettifyPhoneNumber(strippedNumber));
    }

    function validFieldValues(submitData: JobPost) {
        const { companyName, contactName, description, email, phoneNumber, link } = submitData;
        return (
            jobTitle.length <= MAX_FIELD_LENGTH.jobTitle &&
            companyName.length <= MAX_FIELD_LENGTH.companyName &&
            contactName.length <= MAX_FIELD_LENGTH.contactName &&
            description.length <= MAX_FIELD_LENGTH.description &&

            (email ? email.length <= MAX_FIELD_LENGTH.email : true) &&
            (phoneNumber ? phoneNumber.length <= MAX_FIELD_LENGTH.phoneNumber : true) &&
            (link ? link.length <= MAX_FIELD_LENGTH.link : true)
        )
    }

    return !jobSubmittedID ? (
        <form className="job-form" onSubmit={formSubmit}>
            <h1>Submit Your Job</h1>
            <Link to="/">
                <button className="button-link">Go Home</button>
            </Link>
            <h3 style={{color: mainErrorMessageColor}}>{mainErrorMessage}</h3>
            <ExistingJobs jobIds={sessionCtx.jobs} />

            {/* * REQUIRED * */}
            {/* jobTitle */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Job Title{RA}</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    required
                    className="job-form__job-title job-form__text-input"
                    value={jobTitle}
                    onChange={e => setJobTitle(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.jobTitle}
                    placeholder="Super Senior Back-Front Developer"
                    disabled={inputsDisabled}
                />
            </div>
            {/* companyName */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Company Name{RA}</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    required
                    className="job-form__company-name job-form__text-input"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.companyName}
                    placeholder="Pied Piper, Inc."
                    disabled={inputsDisabled}
                />
            </div>
            {/* contactName */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Your Name{RA}</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    required
                    className="job-form__text-input full-name-input"
                    value={contactName}
                    onChange={e => setContactName(e.target.value)}
                    pattern={FULL_NAME_REGEX_STR}
                    maxLength={MAX_FIELD_LENGTH.contactName}
                    placeholder="Richard Hendrix"
                    disabled={inputsDisabled}
                />
            </div>
            {/* description */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Job Description{RA}</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <textarea
                    required
                    className="job-form__textarea-input wide-input"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.description}
                    placeholder="Describe the job - high level and skills desired."
                    disabled={inputsDisabled}
                />
            </div>

            {/* * OPTIONAL * */}
            {/* link */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Job Link</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    className="job-form__text-input url-input"
                    value={link}
                    onChange={e => setLink(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.link}
                    type="url"
                    placeholder="https://linkedin.com/jobs/our-awesome-job"
                    disabled={inputsDisabled}
                />
            </div>
            {/* phoneNumber */}
                <div className="candidate-form__field">
                <h2 className="job-form__field-header">Phone Number</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    className="job-form__text-input phone-input"
                    value={phoneNumberDisplay}
                    onChange={e => updatePhoneNumber(e.target.value)}
                    placeholder="123-456-7890"
                    disabled={inputsDisabled}
                />
            </div>
            {/* email */}
            <div className="job-form__field">
                <h2 className="job-form__field-header">Email</h2>
                <p className="job-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    className="job-form__text-input email-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.email}
                    placeholder="gavin.belson@hooli.com"
                    disabled={inputsDisabled}
                />
            </div>
            <button className="job-form__submit-button"
                type="submit"
                disabled={inputsDisabled}>
                    Submit
            </button>
        </form>
    ) : (
        <Redirect to={`/jobs/id/${jobSubmittedID}`} />
    );
}

export default JobSubmitForm;

interface IExistingJobsProps {
    jobIds?: JobGetData['id'][];
}

const ExistingJobs: FC<IExistingJobsProps> = ({ jobIds }) => {
    const [jobs, setJobs] = useState<JobGetData[]>();

    useEffect(() => {
        // If they haven't posted any jobs, the full job data won't be retrieved
        if (jobIds) {
            console.log('Fetching jobs for full data');
            const jobIdsSet = new Set(jobIds);
            console.log(`current job IDs set: ${jobIdsSet}`)
            api.getJobs()
                .then(resp => {
                    const { data, error } = resp.data;
                    if (error) {

                    } else if (data) {
                        const matchingJobs = data.reduce<JobGetData[]>((runningMatchingJobs, currentJob) => {
                            if (jobIdsSet.has(currentJob.id)) {
                                runningMatchingJobs.push(currentJob);
                            }
                            return runningMatchingJobs;
                        }, []);
                        setJobs(matchingJobs);
                    }
                })
                // TODO: implement
                .catch(() => null);
        }
    }, [jobIds]);

    return jobs && jobs.length > 0 ? (
        <div className="job-form__existing-jobs">
            <h2>You already have submitted these jobs</h2>
            <ul>
                {jobs.map(job => <Link to={`/jobs/id/${job.id}`} key={job.id}><li>{job.jobTitle}</li></Link>)}
            </ul>
        </div>
    ) : null;
}