import React, { FC } from 'react';

import ItemBox, { IItemBoxProps } from '../ItemBox/ItemBox';

import './ListItem.css';

const ListItemPlaceHolder: FC = () => (
    <div className="list-item">
        <ItemBox hover={false} shimmer={true}>
            <h2 className="list-item-placeholder-text list-item-placeholder-sm">.</h2>
            <p className="list-item-placeholder-text list-item-placeholder-lg">.</p>
            <p className="list-item-placeholder-text list-item-placeholder-sm">.</p>
            <p className="list-item-placeholder-text list-item-placeholder-lg">.</p>
            <p className="list-item-placeholder-text list-item-placeholder-lg">.</p>
            <p className="list-item-placeholder-text list-item-placeholder-md">.</p>
            <p className="list-item-placeholder-text list-item-placeholder-md">.</p>
        </ItemBox>
    </div>
)

export default ListItemPlaceHolder;