import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import { SessionContextProvider, AuthRoute, AdminRoute } from './contexts/SessionContext';

// Pages
import HomePage from './pages/HomePage';
import PasswordWallPage from './pages/PasswordWallPage';
import JobsListingPage from './pages/JobsListingPage';
import JobPage from './pages/JobPage';
import SubmitJobPage from './pages/SubmitJobPage';
import CandidatesListingPage from './pages/CandidatesListingPage';
import CandidatePage from './pages/CandidatePage';
import SubmitCandidatePage from './pages/SubmitCandidatePage';
import AdminHomePage from './pages/AdminHomePage';
import LogoutPage from './pages/LogoutPage';
import ExplanationPage from './pages/ExplanationPage';
import NotFoundPage from './pages/NotFoundPage';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <SessionContextProvider>
        <Router>
        <Switch>
          <Route exact path="/wall" component={PasswordWallPage} />

          <AuthRoute exact path="/" childComponent={HomePage} />

          <Route exact path="/logout" component={LogoutPage} />
          <Route exact path="/thejobsboard" component={ExplanationPage} />

          <Route exact path="/jobs" render={()=> <Redirect to="/jobs/all"/>} />
          <AuthRoute exact path="/jobs/all" childComponent={JobsListingPage} />
          <AuthRoute exact path="/jobs/id/:jobId" childComponent={JobPage} />
          <AuthRoute exact path="/jobs/new" childComponent={SubmitJobPage} />

          <Route exact path="/candidates" render={()=> <Redirect to="/candidates/all"/>} />
          <AuthRoute exact path="/candidates/all" childComponent={CandidatesListingPage} />
          <AuthRoute exact path="/candidates/id/:candidateId" childComponent={CandidatePage} />
          <AuthRoute exact path="/candidates/new" childComponent={SubmitCandidatePage} />

          <AdminRoute exact path="/admin" childComponent={AdminHomePage} />

          {/* 404 Fallback when no other routes match (keep at bottom) */}
          <Route component={NotFoundPage} />
        </Switch>
        </Router>
      </SessionContextProvider>
    </div>
  );
}

export default App;
