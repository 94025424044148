import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import ListItem from '../ListItem/ListItem';
import { prettifyPhoneNumber } from '../../util';
import { JobGetData } from '../../api';

import './JobListItem.css';

interface IJobListItem extends JobGetData {
    deleteCB?: (jobId: string) => void;
}

const JobListItem: FC<IJobListItem> = ({id, jobTitle, companyName, contactName, phoneNumber, email, description, link, deleteCB}) => {
    const jobPageLink = '/jobs/id/'+id;
    const prettyPhoneNumber = phoneNumber ? prettifyPhoneNumber(phoneNumber) : '';
    return (
        <ListItem>
        <div className="job-list-item">
            <div className="job-list-item__title-company-box">
                <Link to={jobPageLink}><h2 className="job-list-item__job-title">{jobTitle}</h2></Link>
                <h3 className="job-list-item__company">{companyName}</h3>
            </div>
            <p className="job-list-item__contact"><b>Contact Name</b> {contactName}</p>
            { phoneNumber ? <p className="job-list-item__contact"><b>Phone Number</b> <a href={"tel:" + phoneNumber}>{prettyPhoneNumber}</a></p> : null }
            { email ? <p className="job-list-item__contact"><b>Email</b> <a href={"mailto:" + email}>{email}</a></p> : null }
            { link ? <p className="job-list-item__job-link"><b>Link</b> <a href={link} target="_blank">{link}</a></p> : null }
            { deleteCB ? <JobDeleteButton id={id} cb={deleteCB} /> : null }
        </div>
        </ListItem>
    )
}

export default JobListItem;

interface IJobDeleteButton {
    id: JobGetData['id'];
    cb: IJobListItem['deleteCB'];
}

const JobDeleteButton: FC<IJobDeleteButton> = ({ id, cb }) => {
    function clickDelete(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (cb) {
            // delete callback
            cb(id);
        }
    }

    return (
        <button className="job-list-item__delete_button warn-button" onClick={clickDelete}>DELETE</button>
    )
}