import React, { FC, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

/*
 * 404 Page for unused paths
 */

const REDIRECT_TIME_MS = 1500;

const NotFoundPage: FC = () => {
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        // Redirects after a delay
        setTimeout(() => setRedirect(true), REDIRECT_TIME_MS);
    },[]);

    return redirect ?
        <Redirect to="/" /> :
        (
            <div className="not-found-page">
                <h1><b>Nothing Here!</b></h1>
                <h2><b>Redirecting Home...</b></h2>
                <p><Link to="/">Click here</Link> if it doesn't redirect in a second</p>
            </div>
        )
}

export default NotFoundPage;