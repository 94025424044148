import React, { FC } from 'react';

import './AdminPanelFilterBox.css';

export interface IAdminPanelFilterBox {
    header: string;
}

export const AdminPanelFilterBox: FC<IAdminPanelFilterBox> = ({ header, children }) => (
    <div className="admin-panel-filter-box">
        <h3 className="admin-panel-filter-box__header">{header}</h3>
        {children}
    </div>
);

export default AdminPanelFilterBox;

export interface IAdminPanelFilterBoxItem {
    label?: string;
}

export const AdminPanelFilterBoxItem: FC<IAdminPanelFilterBoxItem> = ({ label, children }) => (
    <div className="admin-panel-filter-box-item">
        { label && <label className="admin-panel-filter-box-item__label">{label}</label> }
        {children}
    </div>
);
