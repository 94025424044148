import React, { FC, useState, useEffect, useContext, FormEvent } from 'react';
import { useLocation, Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import SessionContext, { ISessionContext } from '../contexts/SessionContext';
import API from '../parts/Client/Client';
import JobBoardLogo from '../parts/JobBoardLogo/JobBoardLogo';

import { RA } from '../util';

import './PasswordWallPage.css';

const MAX_FIELD_LENGTH = {
    NAME: 35,
    PASSWORD: 50
}

// Default path to redirect to upon successful login
const DEFAULT_REDIRECT_PATH = "/";

interface ILocationState {
    referrer?: string;
}

const PasswordWallPage: FC<RouteComponentProps<any,any,ILocationState>> = (props) => {
    const { referrer } = props.location.state;
    const forwardPath = referrer || DEFAULT_REDIRECT_PATH;

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [mainError, setMainError] = useState('');
    const [mainErrorColor, setMainErrorColor] = useState('white');
    const [inputsDisabled, setInputsDisabled] = useState(false);

    const sessionCtx = useContext<ISessionContext>(SessionContext);

    useEffect(() => {
        if (!sessionCtx.fetched) {
            API.getSession()
                .then(resp => {
                    // GET session - SUCCESS
                    const sess = resp.data;
                    const { authenticated, admin, name, candidate, jobs } = sess.user;
                    sessionCtx.setAuthenticated(authenticated);
                    sessionCtx.setAdmin(admin);
                    if (name) {
                        sessionCtx.setName(name);
                    }
                    if (candidate) {
                        sessionCtx.setCandidate(candidate);
                    }
                    if (jobs) {
                        sessionCtx.setJobs(jobs);
                    }
                    sessionCtx.setFetched(true);
                })
                .catch(err => {
                    // GET session - FAIL
                    // TODO: implement or ignore?
                    console.error(`[password-wall session-get]  Session Get failed: ${err.message}`);
                });
        }
    }, []);

    function setBadError(errorMessage: string) {
        setMainError(errorMessage);
        setMainErrorColor('red');
    }

    function formSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setInputsDisabled(true);

        API.createSession({ password, name })
            .then(({ data }) => {
                // TODO: implement and test
                const { error } = data;
                if (error) {
                    setBadError(error);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setTimeout(() => setInputsDisabled(false), 2500);
                }
                if (data.data) {
                    const sess = data.data;
                    const { authenticated, admin, name, candidate, jobs } = sess.user;
                    sessionCtx.setAuthenticated(authenticated);
                    sessionCtx.setAdmin(admin);
                    if (name) {
                        sessionCtx.setName(name);
                    }
                    if (candidate) {
                        sessionCtx.setCandidate(candidate);
                    }
                    if (jobs) {
                        sessionCtx.setJobs(jobs);
                    }
                    sessionCtx.setFetched(true);
                }
            })
            .catch(err => {
                setBadError('Server Error.  Try again later');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setInputsDisabled(false);
            })
    }

    return sessionCtx.authenticated ?
        <Redirect to={forwardPath} />
        : (
        <div className="pwall-page">
            <JobBoardLogo />
            <p className="pwall-page__main-error"
                style={{ color: mainErrorColor }}>{mainError}
            </p>
            <form onSubmit={formSubmit}>
                <div className="pwall-page__field">
                    <h2>Name{RA}</h2>
                    <p className="pwall-page__error">{nameError}</p>
                    <input
                        className="full-name-input"
                        required
                        type="text"
                        autoComplete="username"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="Marty McFly"
                        maxLength={MAX_FIELD_LENGTH.NAME}
                        disabled={inputsDisabled}
                    />
                </div>

                <div className="pwall-page__field">
                    <h2>Password{RA}</h2>
                    <p className="pwall-page__error">{passwordError}</p>
                    <input
                        required
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                        maxLength={MAX_FIELD_LENGTH.NAME}
                        disabled={inputsDisabled}
                    />
                </div>

                <button className="pwall-page__submit-button" 
                    type="submit"
                    disabled={inputsDisabled}>
                        Log In
                </button>
            </form>

            {/* Explanation link */}
            <footer>
                <Link to="/thejobsboard">What is this?</Link>
            </footer>
        </div>
    )
}

export default PasswordWallPage;