import React, { FC } from 'react';

const JOB_BOARD_LOGO_TRANSPARENT_SRC = 'https://cdn.bglad.io/img/nyctnb/logo/jobs/NYCTnB_jobs-board-logo_WIDE-transparent.png';

const JobBoardLogo: FC = () => (
    <img
        src={JOB_BOARD_LOGO_TRANSPARENT_SRC}
        alt="New York Tech and Beer Job Board"
        style={{
            width: '85%',
            maxWidth: 400,
            marginTop: 15
        }}
    />
);

export default JobBoardLogo;