import React, { FC, useState, useEffect, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { JobGetData } from '../api';
import API from '../parts/Client/Client';
import { prettifyPhoneNumber } from '../util';

import './JobPage.css';
import SessionContext from '../contexts/SessionContext';

type JobPageParams = { jobId: string };

interface IJobPageProps extends RouteComponentProps<JobPageParams> {}

const JobPage: FC<IJobPageProps> = (props) => {
    const { jobId }  = props.match.params;

    const sessionCtx = useContext(SessionContext);
    const ownsJob = sessionCtx.jobs && jobId !== '' && sessionCtx.jobs.includes(jobId);

    const [jobTitle, setJobTitle] = useState<JobGetData['jobTitle']>('');
    const [companyName, setCompanyName] = useState<JobGetData['companyName']>('');
    const [contactName, setContactName] = useState<JobGetData['contactName']>('');
    const [description, setDescription] = useState<JobGetData['description']>('');

    const [email, setEmail] = useState<JobGetData['email']>('');
    const [phoneNumber, setPhoneNumber] = useState<JobGetData['phoneNumber']>('');
    const [link, setLink] = useState<JobGetData['link']>('');

    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState('');

    const [jobDeleted, setJobDeleted] = useState<boolean>(false);

    useEffect(() => {
        API.getJobById(jobId)
            .then(({ data }) => {
                // Handle executed submit
                if (data.error) {
                    setMainErrorMessage(data.error);
                } else if (data.data) {
                    const d = data.data;
                    setJobTitle(d.jobTitle);
                    setCompanyName(d.companyName);
                    setContactName(d.contactName);
                    setDescription(d.description);
                    setEmail(d.email || '');
                    setPhoneNumber(d.phoneNumber || '');
                    setPhoneNumberDisplay(d.phoneNumber ? prettifyPhoneNumber(d.phoneNumber) : '');
                    setLink(d.link || '');
                }
            })
            .catch(err => {
                // Handle error
                setMainErrorMessage(err.message);
            })
    }, [jobId])

    function clickDelete(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()

        if (ownsJob
                && window.confirm(`Are you sure you want to delete job '${jobTitle}'`)) {
            API.deleteJob(jobId)
                .then(resp => {
                    const { success, error } = resp.data;
                    if (success) {
                        setMainErrorMessage('Job Deleted! Redirecting...');
                        if (sessionCtx.jobs && sessionCtx.jobs.length > 0) {
                            // deleting job from context
                            sessionCtx.jobs.splice(sessionCtx.jobs.findIndex(currJobId => currJobId === jobId),1);
                            sessionCtx.setJobs(sessionCtx.jobs);
                        }
                        setTimeout(() => setJobDeleted(true), 1000);
                    } else if (error) {
                        setMainErrorMessage(`DELETE error: ${error}`);
                    } else {
                        setMainErrorMessage(`DELETE error (unspecified).  Please try again later.`);
                    }
                })
                .catch(err => {
                    setMainErrorMessage(`DELETE error: ${err.message}`);
                })
        }
    }

    return !jobDeleted ? (
        <div className="job-page">
            <h3 className="job-page__main-error">{mainErrorMessage}</h3>
            <h1 className="job-page__job-title">{jobTitle}</h1>
            <h3 className="job-page__company-name">{companyName}</h3>
            <div className="job-page__contact-box">
                <div>
                    <h3 className="job-page__subheader">Contact</h3>
                    <p>{contactName}</p>
                </div>
                { email ?
                    <div>
                        <h3 className="job-page__subheader">Email</h3>
                        <p><a href={"mailto:" + email}>{email}</a></p>
                    </div>
                    :
                    null
                }
                { phoneNumber ?
                    <div>
                        <h3 className="job-page__subheader">Phone</h3>
                        <p><a href={"tel:" + phoneNumber}>{phoneNumberDisplay}</a></p>
                    </div>
                    :
                    null
                }
            </div>
            { link ?
                <div className="job-page__link-box">
                    <h2 className="job-page__subheader">Link</h2>
                    <p><a href={link} target="_blank">{link}</a></p>
                </div>
                :
                null
            }
            <div className="job-page__description-box">
                <h2 className="job-page__subheader">Description</h2>
                <p className="job-page__description">{description}</p>
            </div>
            <div className="job-page__back-links">
                { ownsJob ? <button className="job-page__delete-button warn-button" onClick={clickDelete}>DELETE</button> : null }
                <Link to="/jobs/all">
                    <button className="button-link">Back to Jobs</button>
                </Link>
                <Link to="/">
                    <button className="button-link">Go Home</button>
                </Link>
            </div>
        </div>
    ) : (
        <Redirect to="/" />
    )
}

export default JobPage;