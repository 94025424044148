import React, { FC, useContext } from 'react';
import SessionContext from '../contexts/SessionContext';
import { RouteProps } from 'react-router';
import { Link } from 'react-router-dom';
import JobBoardLogo from '../parts/JobBoardLogo/JobBoardLogo';

import './HomePage.css';

interface IHomePageProps extends RouteProps {}

 const HomePage: FC<IHomePageProps> = (props) => {
    const sessionCtx = useContext(SessionContext);
    return (
        <div className="home-page">
            <JobBoardLogo />
            {
                sessionCtx.admin ?
                <div className="home-page__admin-link-box">
                    <Link to="/admin">
                        <button className="button-link">Admin</button>
                    </Link>
                </div>
                :
                null
            }

            <div className="home-page__intro">
                <p>Welcome to the New York Tech &amp; Beer Job Board!  
                    Here, you can find members who are looking for work, and jobs posted by members.  
                    Let's help each other out!
                </p>
            </div>

            <div className="home-page__buttons-box">
                <h2>Listing</h2>
                <Link to="/jobs/all">
                    <button className="button-link">Jobs</button>
                </Link>
                <Link to="/candidates/all">
                    <button className="button-link">Candidates</button>
                </Link>
            </div>

            <div className="home-page__buttons-box">
                <h2>Submit</h2>
                <Link to="/jobs/new">
                    <button className="button-link">New Job</button>
                </Link>
                <Link to="/candidates/new">
                    <button className="button-link">New Candidate</button>
                </Link>
            </div>
        </div>
    );
}

export default HomePage;