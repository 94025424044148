import React, { FC, useState, useEffect } from 'react';
import { RouteProps }  from 'react-router';
import { Link } from 'react-router-dom';

import { CandidateGetData } from '../api';
import CandidatesListing from '../parts/CandidatesListing/CandidatesListing';
import API from '../parts/Client/Client';
import ListItemPlaceHolder from '../parts/ListItem/ListItemPlaceHolder';
import { processCandidates, DEFAULT_PLACEHOLDER_WAIT_MS, DEFAULT_LISTING_PLACEHOLDERS } from '../util';


interface ICandidateListingPageProps extends RouteProps {}

const CandidatesListingPage: FC<ICandidateListingPageProps> = (props) => {
    const [ candidates, setCandidates ] = useState<CandidateGetData[]>([]);
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [mainErrorColor, setMainErrorColor] = useState('white');
    const [fetchAttempted, setFetchAttempted] = useState(false);
    const [showPlaceHolders, setShowPlaceHolders] = useState(false);

    setTimeout(() => {
        // Shows placeholders if the fetch is taking a while
        if (!fetchAttempted) {
            console.log('Set PLACEHOLDERS=true');
            setShowPlaceHolders(true);
        }
    }, DEFAULT_PLACEHOLDER_WAIT_MS);

    function errorMessage(message: string) {
        setMainErrorMessage(message);
        setMainErrorColor('red');
    }

    function neutralMessage(message: string) {
        setMainErrorMessage(message);
        setMainErrorColor('white');
    }

    useEffect(() => {
        API.getCandidates()
            .then(resp => {
                setFetchAttempted(true);
                const { data, error } = resp.data;
                if (error) {
                    return errorMessage(error);
                }

                if (data && data.length > 0) {
                    setCandidates(processCandidates(data, true));
                    setMainErrorMessage('');
                } else {
                    neutralMessage('No Candidates Yet!');
                }
            })
            .catch(err => {
                errorMessage(err.message);
            })
    }, []);

    return (
        <div className="candidates-listing-page">
            <h1>Candidates</h1>
            <Link to="/">
                <button className="button-link">Go Home</button>
            </Link>
            <h3 className="candidates-listing-page__main-error" style={{color: mainErrorColor}}>{mainErrorMessage}</h3>
            {
                fetchAttempted ?
                    <CandidatesListing listing={candidates} />
                    :
                    (showPlaceHolders ? DEFAULT_LISTING_PLACEHOLDERS : null)
            }
        </div>
    )
}

export default CandidatesListingPage;