import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../contexts/SessionContext';

import PersonalSessionPanel from '../parts/AdminPanels/PersonalSessionPanel';
import JobsPanel from '../parts/AdminPanels/JobsPanel';
import CandidatesPanel from '../parts/AdminPanels/CandidatesPanel';
import SessionRecordsPanel from '../parts/AdminPanels/SessionRecordsPanel';

import './AdminHomePage.css';


const AdminHomePage: FC = () => {
    const sessionCtx = useContext(SessionContext);

    return (
        <div className="admin-home-page">
            <h1>Welcome to the Admin Page, <span className="admin-home-page__username-label">{sessionCtx.name?.toUpperCase()}</span></h1>
            <div>
                <Link to="/">
                    <button className="button-link">Go To User Home</button>
                </Link>
            </div>

            <PersonalSessionPanel />

            <SessionRecordsPanel />

            <CandidatesPanel />

            <JobsPanel />
        </div>
    )
}

export default AdminHomePage;
