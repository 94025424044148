import React, { FC, useState, FormEvent, KeyboardEvent, useContext, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import { CandidatePost } from '../../api';
import API from '../Client/Client';
import {prettifyPhoneNumber, stripPhoneNumber, cleanUrl, RA, FULL_NAME_REGEX_STR } from '../../util';

import './CandidateSubmitForm.css';
import SessionContext from '../../contexts/SessionContext';

// TODO: Revisit Tagline and Description placeholders
// TODO: add validation before POST
// TODO: add POST

const MAX_FIELD_LENGTH = {
    name: 35,
    tagline: 100,
    description: 350,
    phoneNumber: 15,
    email: 80,
    website: 100
};

interface ICandidateSubmitFormProps {}

const CandidateSubmitForm: FC = (props) => {
    const sessionCtx = useContext(SessionContext);

    // Required fields
    const [name, setName] = useState<CandidatePost['name']>('');
    const [tagline, setTagline] = useState<CandidatePost['tagline']>('');
    const [description, setDescription] = useState<CandidatePost['description']>('');

    // Optional fields
    const [email, setEmail] = useState<CandidatePost['email']>('');
    const [phoneNumber, setPhoneNumber] = useState<CandidatePost['phoneNumber']>('');
    const [website, setWebsite] = useState<CandidatePost['website']>('');

    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState<string>();
    const [mainErrorMessage, setMainErrorMessage] = useState<string>();
    const [mainErrorMessageColor, setMainErrorMessageColor] = useState('black');

    const [inputsDisabled, setInputsDisabled] = useState(false);

    function setBadError(errMessage: string) {
        setMainErrorMessage(errMessage);
        setMainErrorMessageColor('red');
    }

    function updatePhoneNumber(phoneInput: string) {
        const strippedNumber = stripPhoneNumber(phoneInput);
        if (strippedNumber.length > MAX_FIELD_LENGTH.phoneNumber) {
            return;
        }
        setPhoneNumber(strippedNumber);
        setPhoneNumberDisplay(prettifyPhoneNumber(strippedNumber));
    }

    function phoneNumberInputKeyUp(e: KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8 && phoneNumber) {
            const newPhoneNumber = phoneNumber.slice(0,-1);
            setPhoneNumber(newPhoneNumber);
            setPhoneNumberDisplay(prettifyPhoneNumber(newPhoneNumber));
        }
    }


    function formSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setInputsDisabled(true);

        const submitData: CandidatePost = Object.assign({},
            {name,tagline,description},
            email && email.length > 0 ? {email} : {},
            phoneNumber && phoneNumber.length > 0 ? {phoneNumber} : {},
            website && website.length > 0 ? {website: cleanUrl(website)} : {}
        );
        if (!validSubmitValues(submitData)) {
            return console.error('Invalid form inputs');
        }

        API.createCandidate(submitData)
            .then(resp => {
                const { error, data } = resp.data;
                if (error) {
                    setBadError(error);
                    setInputsDisabled(false);
                } else if (data && data.id) {
                    setMainErrorMessageColor('green');
                    setMainErrorMessage('Successfully posted!');
                    // Adding candidate ID to session record
                    sessionCtx.setCandidate(data.id);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch(err => {
                // TODO: handle error
                setBadError(err.message);
                setInputsDisabled(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
    }

    function validSubmitValues(submitData: CandidatePost) {
        const { name, tagline, description, email, phoneNumber, website } = submitData;
        return (
            name.length <= MAX_FIELD_LENGTH.name &&
            tagline.length <= MAX_FIELD_LENGTH.tagline &&
            description.length <= MAX_FIELD_LENGTH.description &&

            (email ? email.length <= MAX_FIELD_LENGTH.email : true) &&
            (phoneNumber ? phoneNumber.length <= MAX_FIELD_LENGTH.phoneNumber : true) &&
            (website ? website.length <= MAX_FIELD_LENGTH.website : true)
        )
    }

    return !sessionCtx.candidate ? (
        <form className="candidate-form" onSubmit={formSubmit}>
            <h1>Submit Your Candidate Info</h1>
            <Link to="/">
                <button className="button-link">Go Home</button>
            </Link>
            <h3 style={{color: mainErrorMessageColor}}>{mainErrorMessage}</h3>
            {/* * REQUIRED * */}
            {/* name */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">Name{RA}</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <input
                    required
                    className="candidate-form__text-input full-name-input"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    pattern={FULL_NAME_REGEX_STR}
                    maxLength={MAX_FIELD_LENGTH.name}
                    placeholder="Richard Hendricks"
                    disabled={inputsDisabled}
                />
            </div>
            {/* tagline */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">Tagline{RA}</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <input 
                    required
                    className="candidate-form__text-input candidate-form__tagline"
                    value={tagline}
                    onChange={e => setTagline(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.tagline}
                    placeholder="E.g.: Backend developer passionate about building distributed systems."
                    disabled={inputsDisabled}
                />
            </div>
            {/* description */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">About Me{RA}</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <textarea 
                    required
                    className="candidate-form__textarea-input wide-input"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.description}
                    placeholder="E.g.: I'm a frontend developer with X years of experience in Y frameworks.  I'm looking for Z jobs."
                    disabled={inputsDisabled}
                />
            </div>

            {/* * OPTIONAL * */}
            {/* website */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">Website</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <input 
                    className="candidate-form__text-input url-input"
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.website}
                    type="url"
                    placeholder="https://my-website.com (or your linkedin or github)"
                    disabled={inputsDisabled}
                />
            </div>
            {/* phoneNumber */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">Phone Number</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <input 
                    className="candidate-form__text-input phone-input"
                    value={phoneNumberDisplay}
                    onChange={e => updatePhoneNumber(e.currentTarget.value)}
                    onKeyUp={phoneNumberInputKeyUp}
                    placeholder="123-456-7890"
                    disabled={inputsDisabled}
                />
            </div>
            {/* email */}
            <div className="candidate-form__field">
                <h2 className="candidate-form__field-header">Email</h2>
                <p className="candidate-form__error-message" style={{color: 'red'}}>{}</p>
                <input 
                    className="candidate-form__text-input email-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    maxLength={MAX_FIELD_LENGTH.email}
                    placeholder="richard.hendricks@gmail.com"
                    disabled={inputsDisabled}
                />
            </div>
            <button className="candidate-form__submit-button"
                type="submit"
                disabled={inputsDisabled}>
                    Submit
            </button>
        </form>
    ) : (
        <div className="candidate-form">
            <h1>You Successfully Submitted Yourself As a Candidate</h1>
            <p><Link to={`/candidates/id/${sessionCtx.candidate}`}>Click here</Link> to see your entry.</p>
            <Link to="/">
                <button className="button-link">Go Home</button>
            </Link>
        </div>
    );
}

export default CandidateSubmitForm;
