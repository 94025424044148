import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import ListItem from '../ListItem/ListItem';
import { prettifyPhoneNumber } from '../../util';
import { CandidateGetData } from '../../api';

import './CandidateListItem.css';

interface ICandidateListItem extends CandidateGetData {
    deleteCB?: (candidateId: string) => void;
}

const CandidateListItem: FC<ICandidateListItem> = ({id, name, tagline, description, phoneNumber, email, website, deleteCB}) => {
    const candidatePageLink = '/candidates/id/'+id;
    const prettyPhoneNumber = phoneNumber ? prettifyPhoneNumber(phoneNumber) : '';
    return (
        <ListItem>
        <div className="candidate-list-item">
            <div className="candidate-list-item__name-tagline-box">
                <Link to={candidatePageLink}><h2 className="candidate-list-item__name">{name}</h2></Link>
                <p className="candidate-list-item__tagline">{tagline}</p>
                <p className="candidate-list-item__seeking-description">{description}</p>
            </div>
            { website ? <p><b>Website</b> <a href={website} target="_blank">{website}</a></p> : null }
            { phoneNumber ? <p><b>Phone Number</b> <a href={"tel:" + phoneNumber}>{prettyPhoneNumber}</a></p> : null }
            { email ? <p className="candidate"><b>Email</b> <a href={"mailto:" + email}>{email}</a></p> : null }

            { deleteCB ? <CandidateDeleteButton id={id} cb={deleteCB} /> : null }
        </div>
        </ListItem>
    )
}

export default CandidateListItem;

interface ICandidateDeleteButton {
    id: CandidateGetData['id'];
    cb: ICandidateListItem['deleteCB'];
}

const CandidateDeleteButton: FC<ICandidateDeleteButton> = ({ id, cb }) => {
    function clickDelete(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (cb) {
            // candidate delete callback
            cb(id);
        }
    }

    return <button className="candidate-list-item__delete_button warn-button" onClick={clickDelete}>DELETE</button>
}