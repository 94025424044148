import React, { FC, useState, useEffect, useContext } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import { CandidateGetData } from '../api';
import API from '../parts/Client/Client';
import { prettifyPhoneNumber } from '../util';

import './CandidatePage.css';
import SessionContext from '../contexts/SessionContext';

type CandidatePageParams = { candidateId: string };

interface ICandidatePageProps extends RouteComponentProps<CandidatePageParams> {}

const CandidatePage: FC<ICandidatePageProps> = (props) => {
    const { candidateId } = props.match.params;

    const sessionCtx = useContext(SessionContext);
    const ownsCandidate = sessionCtx.candidate
                            && candidateId !== ''
                            && sessionCtx.candidate === candidateId;

    const [name, setName] = useState<CandidateGetData['name']>('');
    const [tagline, setTagline] = useState<CandidateGetData['tagline']>('');
    const [description, setDescription] = useState<CandidateGetData['description']>('');

    const [website, setWebsite] = useState<CandidateGetData['website']>();
    const [phoneNumber, setPhoneNumber] = useState<CandidateGetData['phoneNumber']>('');
    const [email, setEmail] = useState<CandidateGetData['email']>('');

    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const [phoneNumberDisplay, setPhoneNumberDisplay] = useState('');

    const [candidateDeleted, setCandidateDeleted] = useState<boolean>(false);

    useEffect(() => {
        API.getCandidateById(candidateId)
            .then(({ data }) => {
                // Handle executed submit
                if (data.error) {
                    setMainErrorMessage(data.error);
                } else {
                    const d = data.data!;
                    setName(d.name);
                    setTagline(d.tagline);
                    setDescription(d.description);
                    setWebsite(d.website || '');
                    setPhoneNumber(d.phoneNumber || '');
                    setPhoneNumberDisplay(d.phoneNumber ? prettifyPhoneNumber(d.phoneNumber) : '');
                    setEmail(d.email || '');
                }
            })
            .catch(err => {
                // Handle error
                setMainErrorMessage(err.message);
            })
    }, [candidateId]);

    function clickDelete(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        if (ownsCandidate
                && window.confirm(`Are you sure you want to delete candidate '${name}'?`)) {
                API.deleteCandidate(candidateId)
                    .then(resp => {
                        const { success, error } = resp.data;
                        if (success) {
                            setMainErrorMessage('Candidate Deleted!  Redirecting...')
                            sessionCtx.setCandidate();
                            setTimeout(() => setCandidateDeleted(true), 1000);
                        } else if (error) {
                            setMainErrorMessage('DELETE error: ${err.message}');
                        }
                    })
                    .catch(err => {
                        setMainErrorMessage(`DELETE error: ${err.message}`);
                    })
        }
    }

    return !candidateDeleted ? (
        <div className="candidate-page">
            <h3 className="candidate-page__main-error">{mainErrorMessage}</h3>
            <div>
                <h1 className="candidate-page__name">{name}</h1>
                <p className="candidate-page__tagline">{tagline}</p>
            </div>
            {
                email ?
                <div className="candidate-page__email-box">
                    <p><b>Email</b> <a href={"mailto:" + email}>{email}</a></p>
                </div>
                :
                null
            }
            {
                phoneNumber ?
                <div className="candidate-page__phone-box">
                    <p><b>Phone</b> <a href={"tel:" + phoneNumber}>{phoneNumberDisplay}</a></p>
                </div>
                :
                null
            }
            {
                website ?
                <div className="candidate-page__website-box">
                    <p><b>Website</b> <a href={website} target="_blank">{website}</a></p>
                </div>
                :
                null
            }
            <div className="candidate-page__description-box">
                <h2 className="candidate-page__description-title">About Me</h2>
                <p className="candidate-page__description">{description}</p>
            </div>

            <div className="candidate-page__back-links">
                { ownsCandidate ? <button className="candidate-page__delete-button warn-button" onClick={clickDelete}>DELETE</button> : null }
                <Link to="/candidates/all">
                    <button className="button-link">Back to Candidates</button>
                </Link>
                <Link to="/">
                    <button className="button-link">Go Home</button>
                </Link>
            </div>
        </div>
    ) : (
        <Redirect to="/" />
    )
}

export default CandidatePage;